$pnp.setup({
  headers: {
    Accept: "application/json; odata=verbose",
  },
});

Vue.component(`areas-atuacao-home`, {
  mixins: [translateMixin],
  template: `
    <div class="areas-atuacao-home row d-flex justify-content-center mt-5">
        <div class="col-sm-4 text-center mb-5" v-for="item in items">
            <i :class="item.Icone" class="area-icon"></i>
            <h3 class="title mt-4 mb-4"> {{ isEnglish ? item.TitleEN : item.Title }} </h3>
            <p v-html="isEnglish ? item.ResumoEn : item.Resumo" class="resumo d-none"></p>
            <a :href="getDisp(item.SeoURL)" :title="isEnglish ? 'visit ' + item.TitleEN : 'ir para ' + item.Title" class="saiba-mais">
                <span> {{ isEnglish ? 'know more' : 'saiba mais' }}</span>
                <i class="fas fa-angle-double-right ml-1"></i>
            </a>
        </div>
    </div>
    `,
  data() {
    var listName = `AreasAtuacao`;
    return {
      listName: listName,
      items: [],
      listURL: `${_spPageContextInfo.webServerRelativeUrl}lists/${listName}`,
    };
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      return $pnp.sp.web
        .getList(this.listURL)
        .items.filter(`AreaDestaque eq 1'`)
        .orderBy("OrdemHome", true)
        .top(10)
        .get()
        .then((results) => {
          Vue.set(this, "items", results);
        })
        .catch((err) => {
          this.threatError(
            err,
            `Erro ao obter informações de ${this.listName}`,
          );
        });
    },
    getDisp(url) {
      return `expertise/${url}`;
    },
    threatError(err, msg) {
      console.log(err);
      return swal({
        type: "error",
        text: msg || err,
        title: "Oops!",
      });
    },
  },
});
