$pnp.setup({
  headers: {
    Accept: "application/json; odata=verbose",
  },
});

require("../gerais/shareSX");

Vue.component(`ultimas-noticias-home`, {
  mixins: [translateMixin],
  template: `
    <div class="ultimas-noticias-home mt-5">
        <div class="d-flex justify-content-between" :class="isMobile ? 'flex-wrap' : ''">
            <div class="box-noticias zoom-in" :class="isMobile ? 'mb-3' : 'mr-2'" v-for="item in items" :style="getBackground(item.LinkImagem)">
                <div class="d-flex flex-column content text-white">
                    <a :href="getDisp(item)" :title="isEnglish ? item.TitleEn : item.Title" class="text-white link-conteudo">
                        <h3 class="title"> {{ isEnglish ? item.TitleEN : item.Title }} </h3>
                    </a>

                    <div class="d-flex justify-content-between">
                        <span>{{ isEnglish ? item.AuxCategoria.TitleEN : item.AuxCategoria.Title }}</span>
                        
                        <span class="ml-1 mr-1">|</span>
                    
                        <span>{{ formatDate(item.DataPublicacao) }}</span>
                        
                        <div class="d-none" :class="isMobile ? 'pr-4' : ''">
                            <span class="text-white cursor-pointer share" :rel="item.Id" @click="applyShare(item)">
                                <span class="bt-share"><i class="fas fa-share-alt"></i></span>
                                <div class="content-share-disp" :rel="item.Id"></div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a href="/conteudo" class="btn-redirect mt-5" :title=" isEnglish ? 'see more' : 'veja mais'">
            {{ isEnglish ? 'News' : 'Ver mais conteúdos' }}
            <i class="fas fa-angle-double-right"></i>
        </a>
    </div>
    `,
  data() {
    var listName = `Noticias`;
    return {
      listName: listName,
      items: [],
      listURL: `${_spPageContextInfo.webServerRelativeUrl}lists/${listName}`,
      moment: moment,
      isMobile: window.innerWidth < 996 ? true : false,
    };
  },
  created() {
    this.getItems();
    this.closeShare();
  },
  methods: {
    getItems() {
      var today = moment().format("YYYY-MM-DD[T]HH:mm:ss[Z]");

      return $pnp.sp.web
        .getList(this.listURL)
        .items.select(
          "*, AuxCategoria/Id, AuxCategoria/Title, AuxCategoria/TitleEN"
        )
        .expand("AuxCategoria")
        .filter(`DataPublicacao le datetime'${today}'`)
        .orderBy("Created", false)
        .top(4)
        .get()
        .then((results) => {
          Vue.set(this, "items", results);
        })
        .catch((err) => {
          this.threatError(
            err,
            `Erro ao obter informações de ${this.listName}`
          );
        });
    },
    applyShare(item) {
      $(".ultimas-noticias-home")
        .find(`.share[rel="${item.Id}"] .content-share-disp`)
        .shareSX({
          showLabels: true,
          showIcons: true,
          title: this.isEnglish ? item.TitleEn : item.Title,
          url: this.getDisp(item),
          types: ["facebook", "twitter", "linkedin", "whatsapp", "email"],
        });

      $(`.content-share-disp[rel="${item.Id}"]`).show();
    },
    closeShare() {
      $(document).mouseup(function (e) {
        var container = $(".content-share-disp");

        // if the target of the click isn't the container nor a descendant of the container
        if (
          !container.is(e.target) &&
          container.has(e.target).length === 0 &&
          e.target.tagName != "div"
        ) {
          $(`.content-share-disp`).hide();
        }
      });
    },
    getDisp(conteudo) {

      if ((conteudo.AuxCategoria || {}).Title === "Imprensa" && conteudo.URL) {
        return conteudo.URL;
      }
      return `/conteudo/${conteudo.SeoURL}`;
    },
    isExternalURL(url) {
      const regex =
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
      return regex.test(url);
    },
    getBackground(img) {
      if (img) {
        return `background:linear-gradient(0deg, rgba(90, 125, 170, 0.5), rgba(90, 125, 170, 0.5)), linear-gradient(0deg, rgba(70, 70, 70, 0.4), rgba(70, 70, 70, 0.4)), url('${img}');`;
      }
    },
    formatDate(item) {
      if (this.isEnglish) {
        return moment(item).lang("en-us").format("YYYY.MMM.DD");
      } else {
        return moment(item).lang("pt-br").format("DD.MMM.YYYY");
      }
    },
    threatError(err, msg) {
      console.log(err);
      return swal({
        type: "error",
        text: msg || err,
        title: "Oops!",
      });
    },
  },
});
