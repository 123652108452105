//Plugin de compartilhamento nas redes sociais
var ie =
  window.navigator.userAgent.toString().indexOf("MSIE 9.0") > -1 ||
  window.navigator.userAgent.toString().indexOf("MSIE 10.0") > -1;
jQuery.fn.extend({
  shareSX: function (option) {
    //if(!option.types.length || !option.url){return false;}
    var isEnglish = CurrentLanguageLCID == "en-US";
    var html = `
            <ul class="share-socials mb-0 p-0 nav justify-content-end">`,
      isMobile = window.innerWidth <= 735,
      titlePopup = "",
      titleEmail = option.title;

    if (ie) {
      titlePopup = option.title = option.title
        .replace(/[áàâã]/g, "a")
        .replace(/[éèê]/g, "e")
        .replace(/[óòôõ]/g, "o")
        .replace(/[úùû]/g, "u")
        .replace(/[ç]/g, "c")
        .replace(/\s/g, "_");
    } else {
      titlePopup = option.title = encodeURI(option.title);
    }
    option.content = option.content || "";

    option.types.forEach(function (type) {
      var onclick =
          "PopupCenterDual('" +
          getShareLink(type) +
          "','" +
          option.title +
          "',600,500,$(this))",
        href = "javascript:",
        dynamicClasses = "";
      if (ie) {
        href = "";
      }
      if (option.displayText == true) {
        option.displayText = option.title;
      }

      if (type.toLowerCase() == "whatsapp" || type.toLowerCase() == "email") {
        onclick = "";
        //option.title = encodeURI(option.title);
        href = getShareLink(type);
      }

      option.showLabels =
        option.showLabels == undefined ? true : option.showLabels;
      option.showIcons =
        option.showIcons == undefined ? true : option.showIcons;

      if (option.showLabels) {
        dynamicClasses = "text-active";
      }
      var iconClasses = type == "email" ? "fas fa-envelope" : `fab fa-${type}`;

      dynamicClasses = `icon-active ${iconClasses} ${
        option.showLabels ? " text-active" : ""
      }`;

      if (type.toLowerCase() == "undefined") {
        return false;
      } else {
        html += `<li class="nav-item">
                    <a onclick="${onclick}" href="${href}" data-icon="${type.toLowerCase()}" class="nav-link text-wite" target="_blank">
                        <i class="${dynamicClasses} text-wite"></i> ${
          option.showLabels ? type : ""
        }
					</a>
				</li>`;
      }
    });

    html += "</ul>";
    //apendando o html no seletor

    $(this).html(html);

    //montando url's das redes;
    function getShareLink(type) {
      var link = "";
      var emailHref = "";

      //verificando se o link é externo ou relativo;
      if ((option.url || "").indexOf("http") > -1) {
        option.url = option.url;
      } else {
        option.url = _spPageContextInfo.webAbsoluteUrl + option.url;
      }

      if (type.toLowerCase() == "facebook")
        if (isMobile) {
          link = "https://m.facebook.com/sharer/sharer.php?u=" + option.url;
        } else {
          link = "https://facebook.com/sharer/sharer.php?u=" + option.url;
        }
      else if (type.toLowerCase() == "twitter")
        if (isMobile) {
          link =
            "http://twitter.com/intent/tweet?text=Pellegrina & Monteiro - " +
            option.title +
            ": " +
            option.url;
        } else {
          link =
            "http://twitter.com/home?status=Pellegrina & Monteiro - " +
            option.title.replace(/&/gim, "%26") +
            ": " +
            option.url.replace(/&/gim, "%26");
        }
      else if (type.toLowerCase() == "linkedin")
        link =
          "https://www.linkedin.com/shareArticle?url=" +
          option.url.replace(/&/gim, "%26").replace(/=/g, "/") +
          "&title=" +
          option.title.replace(/&/gim, "%26");
      else if (type.toLowerCase() == "google-plus")
        link =
          "https://plusone.google.com/_/+1/confirm?hl=pt&url=" +
          option.url +
          "&title=" +
          option.title;
      else if (type.toLowerCase() == "whatsapp")
        if (isMobile) {
          link =
            "whatsapp://send?text=" +
            encodeURIComponent(
              "Pellegrina & Monteiro - " +
                decodeURIComponent(option.title) +
                ": " +
                option.url,
            );
        } else {
          link =
            "https://web.whatsapp.com/send?text=" +
            encodeURIComponent(
              "Pellegrina & Monteiro - " +
                decodeURIComponent(option.title) +
                ": " +
                option.url,
            );
        }
      //<a href="" data-action="share/whatsapp/share">Share via Whatsapp web</a>
      else if (type.toLowerCase() == "email") {
        link = getMailToUrl(
          option.content,
          titleEmail,
          encodeURIComponent(option.url),
        );
      }
      return link;
    }
  },
});

//função para abrir a modal e passar lagura e altura para centralizar
window.PopupCenterDual = function (url, title, w, h, element) {
  element.removeAttr("href");

  // Fixes dual-screen position Most browsers Firefox
  var dualScreenLeft =
    window.screenLeft != undefined ? window.screenLeft : screen.left;
  var dualScreenTop =
    window.screenTop != undefined ? window.screenTop : screen.top;
  var width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  var height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  var left = width / 2 - w / 2 + dualScreenLeft;
  var top = height / 2 - h / 2 + dualScreenTop;

  var newWindow = window.open(
    url,
    title,
    "scrollbars=yes, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left +
      "toolbar=no, scrollbars=no, resizable=no, location=no, status=no",
  );

  // Puts focus on the newWindow
  /*if (window.focus) {
	newWindow.focus();
	}*/
  return false;
};

function getMailToUrl(conteudo, titulo, urlShare) {
  var url = "";
  var body = "";
  var encodedLineBreak = "%0A%0A";
  conteudo = conteudo || titulo;
  titulo = titulo.replace(/[&]/g, "%26");

  titulo = titulo.replace(/"/g, "'");

  body =
    getEmailBody(parseMailBodyContent(conteudo)) +
    makeUrlAbsolute(urlShare).replace(/&/gim, "%26").replace(/"/gim, "%22");

  return "mailto:?subject=" + titulo + "&body=" + body;
}

function makeUrlAbsolute(url) {
  if (!url) return window.location.href;

  if (url.indexOf("http") > -1) {
    return url;
  } else {
    return _spPageContextInfo.webAbsoluteUrl + encodeURI(url);
  }
}

function parseMailBodyContent(bodyContent) {
  bodyContent = "<div>" + bodyContent + "</div>";

  var domConteudo = $(bodyContent.replace(/<div>/g, "\n<div>"));

  domConteudo.find("p").each(function () {
    var htmlSemQuebra = $(this)
      .html()
      .replace(/\n/g, "")
      .replace(/<br>/g, "\n");
    $(this).html(htmlSemQuebra);
  });

  bodyContent = domConteudo.text().replace(/\n\n/g, "\n");
  bodyContent = $.trim(domConteudo.text());
  return bodyContent;
}

function getEmailBody(conteudo) {
  var encodedLineBreak = "%0A%0A";
  var textoInicio =
    "Um conteúdo do site Pellegrina & Monteiro foi enviado para você:";
  var textoLink = "Clique para acessar o conteúdo na íntegra: ";
  var body = "";
  conteudo = conteudo;

  if (CurrentLanguageLCID == "en-US") {
    textoLink = "Please click here to access full content: ";
    textoInicio =
      "A content from the Pellegrina & Monteiro website has been sent to you:";
  }

  conteudo = conteudo.replace(/[&]/g, "%26").replace(/"/gim, "%22");
  body =
    textoInicio +
    encodedLineBreak +
    truncateText(conteudo, 335) +
    encodedLineBreak +
    textoLink;

  return body;
}

function truncateText(text, length) {
  if (text.length > length) {
    return text.substring(0, length - 3) + "...";
  } else {
    return text;
  }
}
