const vSelect = require("vue-select").VueSelect;

Vue.component("busca-profissionais-home", {
  mixins: [translateMixin],
  components: { vSelect },
  template: `
        <div class="busca-profissionais-home container" v-if="isLoaded">
            <div class="row">
                <div class="text-left wow fadeIn" data-wow-delay=".5s" :class="isMobile ? 'col-12 mb-5' : 'col-sm-4'">
                    <input class="name w-100" type="text" id="search" placeholder="Nome" name="Nome do Profissional" @keypress.enter.prevent="redirect()" v-model="filtro.keyWord" />
                </div>
                <div class="text-left wow fadeIn" data-wow-delay=".6s" :class="isMobile ? 'col-12 mb-5' : 'col-sm-4'">
                    <v-select :options="areas" v-model="filtro.area" label="Title" placeholder="Área de Atuação">
                        {{ isEnglish ? 'No results found' : 'Nenhum resultado encontrado' }}
                       
                    </v-select>
                </div>
                <div class="wow fadeIn mb-4" data-wow-delay=".7s" :class="isMobile ? 'col-12' : 'col-sm-4'">
                    <a href="javascript:" class="btn" @click="redirect()" :title=" isEnglish ? 'Search Professional' : 'Buscar Profissional'">
                        {{ isEnglish ? 'Search Professional' : 'Buscar Profissional' }}
                    </a>
                </div> 

                <div class="col-12 mt-2" v-if="error">
                    <p><i class="fas fa-exclamation-circle mr-2"></i> Por favor, preencha um dos campos para prosseguir</p>
                </div>
            </div>
        </div>
    `,
  created() {
    this.getAreas();
  },
  methods: {
    redirect() {
      var url;
      if (this.filtro.keyWord || this.filtro.area) {
        this.error = false;
        var filtro = [];

        if (this.filtro.keyWord) filtro.push(`keywords=${this.filtro.keyWord}`);
        if (this.filtro.area) filtro.push(`area=${this.filtro.area.Title}`);

        filtro = filtro.join("&");

        return (window.location.href = `${
          _spPageContextInfo.webAbsoluteUrl
        }/Paginas/profissionais.aspx${filtro ? "#" + filtro : ""}`);
      } else {
        this.error = true;
      }
    },
    getAreas() {
      return $pnp.sp.web
        .getList(
          _spPageContextInfo.webServerRelativeUrl + "/Lists/AreasAtuacao",
        )
        .items.get()
        .then((results) => {
          Vue.set(this, "areas", results);
          return Vue.set(this, "isLoaded", true);
        });
    },
  },
  data() {
    return {
      filtro: {
        area: null,
        keyWord: null,
      },
      error: false,
      areas: [],
      isLoaded: false,
      isMobile: window.innerWidth < 996 ? true : false,
    };
  },
});
