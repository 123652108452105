Vue.component(`contadores-wp`, {
  mixins: [translateMixin],
  template: `
        <div class="container-fluid">
            <div class="row text-white justify-content-center" id="contador">
                <div class="col-sm-3 text-center wow fadeIn" data-wow-delay=".2s" v-if="counts.advogados" :class="isMobile ? 'mb-4' : ''">
                    <h2 id="count1"> <i class="fas fa-plus"></i> {{ counts.advogados.Contagem }}</h2>
                    <h4> {{ isEnglish ? counts.advogados.TitleEN : counts.advogados.Title }}</h4>
                </div>
                <div class="col-sm-3 text-center wow fadeIn" data-wow-delay=".3s" v-if="counts.estagiarios" :class="isMobile ? 'mb-4' : ''">
                    <h2 id="count2"> <i class="fas fa-plus"></i> {{ counts.estagiarios.Contagem }}</h2>
                    <h4> {{ isEnglish ? counts.estagiarios.TitleEN : counts.estagiarios.Title }}</h4>
                </div>
                <div class="col-sm-3 text-center wow fadeIn" data-wow-delay=".4s" v-if="counts.colabs" :class="isMobile ? 'mb-4' : ''">
                    <h2 id="count3"> <i class="fas fa-plus"></i> {{ counts.colabs.Contagem }}</h2>
                    <h4> {{ isEnglish ? counts.colabs.TitleEN : counts.colabs.Title }}</h4>
                </div>
                <!-- <div class="col-sm-3 text-center wow fadeIn" data-wow-delay=".5s" v-if="counts.clientes" :class="isMobile ? 'mb-4' : ''">
                    <h2 id="count4"> <i class="fas fa-plus"></i> {{ counts.clientes.Contagem }}</h2>
                    <h4> {{ isEnglish ? counts.clientes.TitleEN : counts.clientes.Title }}</h4>
                </div> -->
            </div>
        </div>
      `,
  data() {
    var listName = `InformacoesAdicionais`;
    return {
      listName: listName,
      items: [],
      listURL: `${_spPageContextInfo.webServerRelativeUrl}lists/${listName}`,
      counts: {
        advogados: null,
        estagiarios: null,
        colabs: null,
        clientes: null,
      },
      scroll: false,
      isMobile: window.innerWidth < 996 ? true : false,
    };
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      return $pnp.sp.web
        .getList(this.listURL)
        .items.orderBy("Ordem", true)
        .top(4)
        .usingCaching()
        .get()
        .then((results) => {
          this.counts.advogados = results.find(
            (item) => item.TipoContagem == "Advogado",
          );
          this.counts.estagiarios = results.find(
            (item) => item.TipoContagem == "Estagiario",
          );
          this.counts.colabs = results.find(
            (item) => item.TipoContagem == "Colaborador",
          );
          //   this.counts.clientes = results.find(
          //     (item) => item.TipoContagem == "Cliente",
          //   );

          Vue.set(this, "items", results);
        })
        .catch((err) => {
          this.threatError(
            err,
            `Erro ao obter informações de ${this.listName}`,
          );
        });
    },
    //     var $document = $(document);
    //     console.log($document.scrollTop());
    //     if (
    //       $document.scrollTop() >= 1700 &&
    //       $document.scrollTop() <= 1950 &&
    //       !this.scroll
    //     ) {
    //       function counter(id, start, end, duration) {
    //         let obj = document.getElementById(id),
    //           current = start,
    //           range = end - start,
    //           increment = end > start ? 1 : -1,
    //           step = Math.abs(Math.floor(duration / range)),
    //           timer = setInterval(() => {
    //             current += increment;
    //             obj.textContent = current;
    //             if (current == end) {
    //               clearInterval(timer);
    //             }
    //           }, step);
    //       }
    //       counter("count1", 0, (this.counts.advogados || 0).Contagem, 3000);
    //       counter("count2", 0, (this.counts.estagiarios || 0).Contagem, 3000);
    //       counter("count3", 0, (this.counts.colabs || 0).Contagem, 3000);
    //       counter("count4", 0, (this.counts.clientes || 0).Contagem, 3000);
    //       this.scroll = true;
    //     } else {
    //       return "";
    //     }
    // },
    threatError(err, msg) {
      console.log(err);
      return swal({
        type: "error",
        text: msg || err,
        title: "Oops!",
      });
    },
  },
});
