window.methodsSections = {
  methods: {
    getItem() {
      return $pnp.sp.web
        .getList(this.listURL)
        .items.select("*")
        .top(10)
        .usingCaching()
        .get()
        .then((items) => {
          Vue.set(this, "conteudoHome", items);
        })
        .catch((err) => {
          this.threatError(err, `Erro ao obter itens de ${this.listName}`);
        });
    },
    getBackground(img, linearGradient, light) {
      if (img && !linearGradient) {
        return `background-image:url(${img});`;
      }
      if (img && linearGradient && !light) {
        return `background-image: linear-gradient(0deg, rgba(90, 125, 170, 0.6), rgba(90, 125, 170, 0.6)), linear-gradient(0deg, rgba(70,70,70,0.5), rgba(70,70,70,0.5)), url('${img}');`;
      }
      if (img && linearGradient && light) {
        return `background-image: linear-gradient(0deg, rgba(90, 125, 170, 0.5), rgba(90, 125, 170, 0.5)), linear-gradient(0deg, rgba(70,70,70,0.1), rgba(70,70,70,0.1)), url('${img}');`;
      }
    },
    threatError(err, msg) {
      return swal({
        type: "error",
        text: msg || err,
        title: "Oops!",
      });
    },
  },
  data() {
    var listName = "HomeConteudo";
    return {
      listName: listName,
      listURL: `${_spPageContextInfo.webServerRelativeUrl}lists/${listName}`,
      conteudoHome: [],
      isMobile: window.innerWidth < 996 ? true : false,
      isLoaded: false,
    };
  },
  created() {
    this.getItem().then(() => (this.isLoaded = true));
  },
};
