$pnp.setup({
  headers: {
    Accept: "application/json; odata=verbose",
  },
});

//All
require("./scripts-sections");
//  Equipe Especializada
require("./busca-profissionais-home");
// Nossa Expertise
require("./areas-atuacao-home");
//Contadores Home
require("../gerais/_contadores");
//ÚLTIMAS NOTÍCIAS
require("./ultimas-noticias-home");
// FORMULARIO Newsletter
require("../home/formulario")

// Nossa Expertise
Vue.component("nossa-expertise", {
  mixins: [translateMixin, methodsSections],
  template: `
        <element>
            <template v-for="item in conteudoHome" v-if="item.LocalHome == 'Expertise' && isLoaded">
                <section :style="getBackground(item.LinkImagem, true)" class="nossa-expertise">
                    <div class="container-fluid text-center">
                        <h2 class="title">{{ isEnglish ? item.TitleEn : item.Title }}</h2>
                        <areas-atuacao-home class="wow fadeIn" data-wow-delay=".5s" />
                    </div>
                </section>
            </template>
        </element>
    `,
});

//  Equipe Especializada
Vue.component("equipe-especializada", {
  mixins: [translateMixin, methodsSections],
  template: `
        <element>
            <template v-for="item in conteudoHome" v-if="item.LocalHome == 'Equipe Especializada' && isLoaded">
                <section :style="getBackground(item.LinkImagem)" class="equipe">
                    <div class="container-fluid text-center">
                        <h2 class="title">{{ isEnglish ? item.TitleEn : item.Title }}</h2>
                        <p class="conteudo mt-5 mb-5 wow fadeIn" data-wow-delay="0.4s">{{ isEnglish ? item.ConteudoEn : item.Conteudo }}</p>
                        
                        <busca-profissionais-home />

                        <a :href="item.URL" class="btn-redirect" :title=" isEnglish ? 'visit ' + item.TitleEn : 'ir para ' + item.Title" :class="isMobile ? 'mt-2' : 'mt-5'">
                            {{ isEnglish ? item.TitleBotaoEn : item.TitleBotao }}
                            <i class="fas fa-angle-double-right"></i>
                        </a>
                    </div>
                </section>
            </template>
        </element>
    `,
});

// Contadores Home
Vue.component(`contadores-home`, {
  mixins: [translateMixin, methodsSections],
  template: `
      <element>
          <template v-for="item in conteudoHome" v-if="item.LocalHome == 'Contador' && isLoaded">
              <section class="contadores-wp" :style="getBackground(item.LinkImagem, true)">
                  <contadores-wp />
              </section>
          </template>
      </element>
      `,
});

//ÚLTIMAS NOTÍCIAS
Vue.component("ultimas-noticias", {
  mixins: [translateMixin, methodsSections],
  template: `
        <element>
            <template v-for="item in conteudoHome" v-if="item.LocalHome == 'Últimos Conteúdos' && isLoaded">
                <section :style="getBackground(item.LinkImagem)" class="ultimas-noticias">
                    <div class="container-fluid text-center">
                        <h2 class="title">{{ isEnglish ? item.TitleEn : item.Title }}</h2>

                        <ultimas-noticias-home class="title wow fadeIn" data-wow-delay=".5s" />
                    </div>
                </section>
            </template>
        </element>
    `,
});

//  FALE CONOSOCO
Vue.component("fale-conosco", {
  mixins: [translateMixin, methodsSections],
  template: `
        <element>
            <template v-for="item in conteudoHome" v-if="item.LocalHome == 'Fale Conosco' && isLoaded">
                <section :style="getBackground(item.LinkImagem, true, true)" class="fale-conosco-home">
                    <div class="container-fluid text-center text-white p-5 box-glass">
                        <h2 class="title">{{ isEnglish ? item.TitleEn : item.Title }}</h2>
                        <p class="conteudo my-4 wow fadeIn" data-wow-delay=".4s">{{ isEnglish ? item.ConteudoEn : item.Conteudo }}</p>
                        <a :href="item.URL" class="btn rounded-0" :title=" isEnglish ? 'visit ' + item.TitleEn : 'ir para ' + item.Title">
                            {{ isEnglish ? item.TitleBotaoEn : item.TitleBotao }}
                        </a>
                    </div>
                </section>
            </template>
        </element>
    `,
});

// FORMULARIO Newsletter
Vue.component("formulario", {
    mixins: [translateMixin, methodsSections],
    template: `
          <element>
              <template v-for="item in conteudoHome" v-if="item.LocalHome == 'Contador' && isLoaded">
                 <section class="formulario-home">
                     <formulario-home />
                </section>
              </template>
          </element>
      `,
  });

var app = new Vue({
  el: `#nossa-expertise`,
  template: `<nossa-expertise />`,
});

var app = new Vue({
  el: `#equipe-especializada`,
  template: `<equipe-especializada />`,
});

var app = new Vue({
  el: `#contadores-home`,
  template: `<contadores-home />`,
});

var app = new Vue({
  el: `#ultimas-noticias`,
  template: `<ultimas-noticias />`,
});

var app = new Vue({
  el: `#fale-conosco`,
  template: `<fale-conosco />`,
});

var app = new Vue({
    el: `#formulario`,
    template: `<formulario />`,
  });