window.translateMixin = {
  methods: {
    changeLanguage(value) {
      if (CurrentLanguageDecimal == value) {
        return null;
      }

      var today = new Date();
      var oneYear = new Date(today.getTime() + 365 * 24 * 60 * 60 * 1000);
      var url = window.location.href;
      document.cookie =
        "lcid=" + value + ";path=/;expires=" + oneYear.toGMTString();
      location.href = url;
      location.reload();
    },
    formatUrl(url, nextLang) {
      var idioma = nextLang || (this.isEnglish ? "/en-US" : "");
      if (idioma == "pt-BR") idioma = "";

      if (_spPageContextInfo.webAbsoluteUrl.includes("/pi")) {
        return `${
          _spPageContextInfo.webAbsoluteUrl.split("/pi")[0]
        }${idioma}/pi/${
          url.includes("/en-US/") ? url.split("/en-US/")[1] : url
        }`;
      } else {
        return `${_spPageContextInfo.webAbsoluteUrl}${idioma}/${url}`;
      }
    },
  },
  data() {
    return {
      isEnglish: CurrentLanguageDecimal == 1033,
      currentLang: CurrentLanguageDecimal,
    };
  },
};
