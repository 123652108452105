$pnp.setup({
  headers: {
    Accept: "application/json; odata=verbose",
  },
});

import VueSmoothScroll from "vue2-smooth-scroll";
Vue.use(VueSmoothScroll);

// importando os mixins com os metodos do banner - foram separados porque havia muita informação então preferi separar.
require("./banner/banner-texts");
require("./banner/banner-scripts");
require("./busca");

Vue.component("banner-home", {
  template: `
    <element>
        <div class="banner-home" v-if="isLoaded">
            <div class="slide-banner">
                <!-- for-each items-->
                <div class="banner" v-for="item in bannerHome" :class="getCustomClass(item)" v-bind:onclick="(!item.TituloURL && item.URL) ? openURL(item) : undefined" :black-filter="getBlackFilter(item)">
                    <template>
                        <!-- template imagem -->
                        <template v-if="item.TipoBanner == 'Imagem'">
                            <div class="img-banner slide-image" :style="getBackgroundImage(item.LinkImagem)"></div>
                        </template>
                        <!-- /template imagem -->

                        <!-- template vimeo -->
                        <template v-if="item.TipoBanner == 'Vídeo' && item.TipoVideo == 'Vimeo'">
                            <div class="slide-video slide-media" data-video-start="4">
                                <iframe 
                                    class="embed-player slide-media" 
                                    :src="getUrlVideo(item.URLVideo)" 
                                    width="980" height="520" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>
                                </iframe>
                            </div>
                        </template>
                        <!-- /template vimeo -->

                        <!-- tempalte youtube -->
                        <template v-if="item.TipoBanner == 'Vídeo' && item.TipoVideo == 'Youtube'">
                            <iframe 
                                class="embed-player slide-media" 
                                :src="getUrlVideo(item.URLVideo)" 
                                width="980" height="520" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>
                            </iframe>
                        </template>
                        <!-- /template youtube -->

                        <!-- template video local -->
                        <template v-if="item.TipoBanner == 'Vídeo' && item.TipoVideo == 'Local'">
                            <video class="slide-video slide-media" loop muted preload="metadata" :poster="item.LinkImagem">
                                <source :src="getUrlVideo(item.URLVideo)"  type="video/mp4" />
                            </video>
                        </template>
                        <!-- /template video local -->
                    </template>
                    <!--<template v-else>
                        <!-- template imagem -->
                        <template v-if="item.TipoBanner == 'Imagem'">
                            <div class="img-banner slide-image" :style="getBackgroundImage(item.LinkImagem)">
                            </div>
                        </template>
                        <!-- /template imagem -->
                    </template>-->

                    <!-- text banner -->
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-11 col-sm-12">
                                <div class="wow fadeInUp" data-wow-delay="1s" :class="'text-banner ' + alinhamentoConteudo(item.Alinhamento)">
                                    <template v-if="item.ExibirTitulo == 1">
                                        <h1 v-bind:style="estilizarTitulo(item)">
                                            {{ !isEnglish ? item.Title : (item.TitleEN || item.Title) }}<br/>
                                        </h1>
                                        <span v-bind:style="estilizarSubTitulo(item)" class="subtitulo">{{ !isEnglish ? item.SubTitulo : (item.SubTituloEN || item.SubTitulo) }}</span>
                                      
                                    </template>
                                    <div class="btn-banner-home w-60" v-if="item.TituloURL">
                                        <a :href="getURL(!isEnglish ? item.URL : (item.URLEn || item.URL))" 
                                            :title="!isEnglish ? item.TituloURL : item.TituloURLEN" 
                                            class="btn d-sm-inline border border-secondary" 
                                            :style="estilizarBotao(item)"
                                            target="_blank"
                                        >
                                            {{ isEnglish ? item.TituloURLEN : item.TituloURL }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- /text banner -->
                </div>
            </div>
            <div class="text-center busca-home wow fadeInUp" data-wow-delay="1s">
                <busca-home />
            </div>
            <a href="#experiencias" class="scroll-down d-none" v-smooth-scroll="{duration: 1000, offset: -50}" v-show="showBtnScroll">
                <i class="fas fa-arrow-circle-down"></i>
            </a>
        </div>
    </element>
    `,
  mixins: [translateMixin, methodsBannerText, methodsBannerScripts],
  created: function () {
    this.getData()
      .then(() => (this.isLoaded = true))
      .then(this.applyEvents)
      .then(this.scriptsBanner);
  },
  methods: {
    getData: function () {
      var today = moment().format("YYYY-MM-DD[T]HH:mm:ss[Z]");
      var category = `${
        this.isMobile
          ? " and Categoria eq 'Mobile'"
          : " and Categoria eq 'Desktop'"
      }`;

      var web = new $pnp.Web(_spPageContextInfo.siteAbsoluteUrl);
      return web
        .getList(this.listUrl)
        .items.filter(
          `Exibir eq 1 and DataPublicacao le datetime'${today}' and AuxIdioma/lang eq ${CurrentLanguageDecimal}${category}`,
        )
        .select(
          "*, AuxIdioma/Title, CorTitulo/Cor, CorSubTitulo/Cor, CorBotao/Cor, CorBotao/Cor, Categoria",
        )
        .expand("AuxIdioma, CorTitulo, CorSubTitulo, CorBotao, CorBotao")
        .orderBy("Ordem", true)
        .top(100)
        .usingCaching()
        .get()
        .then((banners) => {
          Vue.set(this, "bannerHome", banners);
        })
        .catch((err) => {
          this.threatError(
            err,
            `Erro ao obter  dados da lista ${this.listName}`,
          );
        });
    },
    isSafari() {
      var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
      var iOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (isSafari && iOS) {
        $("#banner").css({
          height: "calc(100vh - 280px)",
        });
      } else if (isSafari) {
        //
      }
    },
    applyEvents: function () {
      //banner + busca height automatico
      var heightDynamic = window.innerHeight + "px";
      var userAgent = window.navigator.userAgent;

      if (window.innerWidth <= 992) {
        heightDynamic = window.innerHeight - 90 + "px";
        this.isSafari();
      }

      $("#banner-height").height(heightDynamic);
      // banner home - slick slider
      Vue.nextTick(function () {
        $(".banner[bg-image]").each(function () {
          var imgURL = $(this).attr("bg-image");
          $(this).css("style", `${imgURL}`);
        });
      });
    },
    openURL(item) {
      if (this.isEnglish) {
        return `window.open('${item.URLEn}')`;
      } else {
        return `window.open('${item.URL}')`;
      }
    },
    threatError: function (msg, data) {
      console.error("error - " + msg + " - " + data);
    },
    getBackgroundImage(image) {
      return `background-image: linear-gradient(0deg, rgba(90, 125, 170, 0.3), rgba(90, 125, 170, 0.3)), linear-gradient(0deg, rgba(70, 70, 70, 0.4), rgba(70, 70, 70, 0.4)), url('${image}')`;
    },
    getURL(URL) {
      if (URL) {
        if (URL == "#") {
          return false;
        } else {
          return URL;
        }
      }
    },
    getCustomClass(item) {
      if (item.TipoBanner == "Vídeo") {
        if (item.TipoVideo == "Vimeo") {
          return "vimeo";
        } else if (item.TipoVideo == "Youtube") {
          return "youtube";
        } else {
          return "video";
        }
      } else {
        return "image";
      }
    },
    getUrlVideo(url) {
      if (url.match(/vimeo/i)) {
        var id = url.split("vimeo.com/")[1];
        return `https://player.vimeo.com/video/${id}?autoplay=1&loop=1&muted=1title=0&background=1`;
      } else if (url.match(/youtube/i) || url.match(/youtu.be/i)) {
        var id = url.split("watch?v=")[1];
        return `https://www.youtube.com/embed/${id}?enablejsapi=1&controls=0&fs=0&iv_load_policy=3&rel=0&showinfo=0&autoplay=1&loop=1&playlist=${id}&mute=1`;
      } else {
        return url;
      }
    },
    getBlackFilter(item) {
      if (item.Filtro) {
        return true;
      }
    },
    onScroll() {
      const OFFSET = 60;
      if (window.pageYOffset < 0) {
        return;
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {
        return;
      }
      this.showBtnScroll = window.pageYOffset < this.lastScrollPosition;
      this.lastScrollPosition = window.pageYOffset;
    },
  },
  mounted() {
    this.lastScrollPosition = window.pageYOffset;
    window.addEventListener("scroll", this.onScroll);
    const viewportMeta = document.createElement("meta");
    viewportMeta.name = "viewport";
    viewportMeta.content = "width=device-width, initial-scale=1";
    document.head.appendChild(viewportMeta);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  data: function () {
    var listName = "Banner";
    return {
      listName: listName,
      listUrl: `${_spPageContextInfo.siteServerRelativeUrl}lists/${listName}`,
      bannerHome: [],
      isMobile: window.innerWidth < 996 ? true : false,
      showBtnScroll: true,
      lastScrollPosition: 0,
      scrollValue: 0,
      isLoaded: false,
    };
  },
});

var app = new Vue({
  el: "#banner-home",
  template: "<banner-home/>",
});
