Vue.component("popup", {
    template: `
  <div class="modal fade" id="popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  

  <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css">
      <div class="modal-dialog modal-lg">
          <div class="modal-content">
              <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
              <div id="mc_embed_signup">
                    <form
                        action="https://pellegrinaemonteiro.us7.list-manage.com/subscribe/post?u=142735565bd60d47b03e69667&amp;id=44e9ea22a3&amp;f_id=0034f5e3f0"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        class="validate"
                        target="_blank"
                        novalidate
                    >
                            <div id="mc_embed_signup_scroll">
                                <img :src="_spPageContextInfo.webServerRelativeUrl + 'style%20library/images/logo-rodape.png'" class="logo" :alt="_spPageContextInfo.webTitle"/>
                                <h2>Cadastre-se na nossa Newsletter</h2>
                                <div class="indicates-required"><span class="asterisk">*</span> indica necessário</div>
                                <div class="mc-field-group">
                                    <label for="mce-EMAIL">Endereço de e-mail <span class="asterisk">*</span> </label>
                                    <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" required />
                                    <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
                                </div>
                                <div class="mc-field-group">
                                    <label for="mce-FNAME">Nome <span class="asterisk">*</span> </label>
                                    <input type="text" value="" name="FNAME" class="required" id="mce-FNAME" required />
                                    <span id="mce-FNAME-HELPERTEXT" class="helper_text"></span>
                                </div>
                                <div class="mc-field-group size1of2">
                                    <label for="mce-PHONE">Número de telefone </label>
                                    <input type="text" name="PHONE" class="" value="" id="mce-PHONE" />
                                    <span id="mce-PHONE-HELPERTEXT" class="helper_text"></span>
                                </div>
                                <div class="mc-field-group">
                                    <label for="mce-MMERGE6">Empresa </label>
                                    <input type="text" value="" name="MMERGE6" class="" id="mce-MMERGE6" />
                                    <span id="mce-MMERGE6-HELPERTEXT" class="helper_text"></span>
                                </div>
                                <div hidden="true"><input type="hidden" name="tags" value="6706319" /></div>
                                <div id="mce-responses" class="clear foot">
                                    <div class="response" id="mce-error-response" style="display: none;"></div>
                                    <div class="response" id="mce-success-response" style="display: none;"></div>
                                </div>
                                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_142735565bd60d47b03e69667_44e9ea22a3" tabindex="-1" value="" /></div>
                                <div class="optionalParent">
                                    <div class="clear foot">
                                        <input type="submit" value="Cadastrar" name="subscribe" id="mc-embedded-subscribe" class="button" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>


              
              </div>
   
              <div class="modal-footer"></div>
          </div>
      </div>
  </div>
  </div>
      `,

    data() {

        return {
           todayToVerify: JSON.parse(localStorage.getItem('todayToVerify')),
            today: moment().format('DD/MM/YYYY')
        }
    },

    created() {
        if( moment(this.today).format('YYYY/MM/DD') !== moment(this.todayToVerify).format('YYYY/MM/DD')){
            localStorage.setItem('todayToVerify', this.today)
        }       
    },

  mounted() {
    if( moment(this.today).format('YYYY/MM/DD') !== moment(this.todayToVerify).format('YYYY/MM/DD')){
        this.handleModal();
    }
  },
  methods: {
    getlocalPreference() {
        
        
    },
    handleModal() { 
       $('#popup').modal("show") 
    }
  }
  
  });
  
  var app = new Vue({
    el: "#popup",
    template: "<popup/>",
  });
  