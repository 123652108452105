window.pnpMixin = function ($pnp) {
	var pnpMixin = {
		methods: {
			getCurrentUser: function () {
				return $pnp.sp.web.siteUsers.getById(_spPageContextInfo.userId).expand('groups').get();
			},
			isUserMemberOf: function (groupName, groups) {
				return [].concat(groups).some(function (group) {
					return group.Title === groupName;
				});
			},
			getRelativeUrl: function (link) {
				return $pnp.util.makeUrlAbsolute(link).replace(location.protocol + '//' + location.hostname, '')
			},
			truncateString: function (str, length) {
				return !str ? "" : str.length > length ? str.substring(0, length - 3) + '...' : str
			},
			getPicture: function (user) {
				return _spPageContextInfo.webAbsoluteUrl + '/_layouts/15/userphoto.aspx?size=L&username=' + user + ''
			},
			getList: function (settings) {
				var request = $pnp.sp.web.getList(settings.listUrl).items
				settings.select ? request.select(settings.select) : null
				settings.orderBy ? request.orderBy(settings.orderBy, settings.orderByAsc) : null
				settings.filter ? request.filter(settings.filter) : null
				settings.expand ? request.expand(settings.expand) : null
				settings.top ? request.expand(settings.top) : null

				return request.get()
			},
			threatError(err, msg) {
				return swal({
					type: `error`,
					text: err + ' - ' + msg,
					title: `Oops!`
				})
			},
			clearFilename(filename) {
				return (filename || '').replace(/[/\\?%*:|"<>]/g, '-');
			},
			exportPDF(item) {
				var _qry = "";
				_qry = "p=" + item.Id;
				var title = "&title=" + this.clearFilename(item.FullName);

				var urlFav = "/_layouts/15/SX.WebSite.Juridicos.SP2013.Downloader/PDFDownloader.ashx?" + _qry + "&lcid=" + CurrentLanguageDecimal + title + '&la=Premios&ff=Profissionais';
				window.location.href = _spPageContextInfo.webAbsoluteUrl + urlFav;
			}
		}
	}
	return pnpMixin;
}($pnp)
