import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
Vue.component("formulario-home", {
  mixins: [translateMixin],
  template: `

<div>
          <div class="modal-content">
              <div class="modal-body">
              <div id="mc_embed_signup">
                  <form
                        action="https://pellegrinaemonteiro.us7.list-manage.com/subscribe/post?u=142735565bd60d47b03e69667&amp;id=44e9ea22a3&amp;f_id=0034f5e3f0"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        class="validate"
                        target="_blank"
                        novalidate
                    >
                            <div id="mc_embed_signup_scroll">
                                <h2 class="form-text text-center">Cadastre-se na nossa Newsletter</h2>
                                <div class="indicates-required"><span class="asterisk">*</span> indica necessário</div>
                                
                            <div class="form-box">
                                <div class="mc-field-group">
                                    <label for="mce-EMAIL">Endereço de e-mail<span class="asterisk">*</span></label>
                                    <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" required/>
                                </div>
                                
                                <div class="mc-field-group">
                                    <label for="mce-FNAME">Nome<span class="asterisk">*</span> </label>
                                    <input type="text" value="" name="FNAME" class="required" id="mce-FNAME" required/>  
                                </div>
                                
                                <div class="mc-field-group">
                                    <label for="mce-PHONE">Número de telefone </label>
                                    <the-mask :mask="['(##) ####-####', '(##) #####-####']" />
                                </div>

                                <div class="mc-field-group">
                                    <label for="mce-MMERGE6">Empresa </label>
                                    <input type="text" value="" name="MMERGE6" class="" id="mce-MMERGE6"/>
                                </div>
                            </div>
                            
                                <div hidden="true"><input type="hidden" name="tags" value="6706319" /></div>
                                <div id="mce-responses" class="buttonForm">
                                    <div class="response" id="mce-error-response" style="display: none;"></div>
                                    <div class="response" id="mce-success-response" style="display: none;"></div>
                                </div>
                                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                               
                                <div class="optionalParent">
                                    <div class="buttonForm">
                                        <input type="submit" value="Cadastrar" name="subscribe" class="cadastroForm"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                        </form>
                    </div>
              </div>
          </div>
      </div>
  </div>
    `, 
})
const handlePhone = (event) => {
    let input = event.target
    input.value = phoneMask(input.value)
}

const phoneMask = (value) => {
    if (!value) return ""
    value = value.replace(/\D/g,'')
    value = value.replace(/(\d{2})(\d)/,"($1) $2")
    value = value.replace(/(\d)(\d{4})$/,"$1-$2")
    return value
};
